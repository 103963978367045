import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FournisseursSideNav from './FournisseursSideNav';

function DealersList() {
  const fournisseurId = localStorage.getItem('id');

  const [dealers, setDealers] = useState([]);
  const [error, setError] = useState(null);
  const [showAddDealerPopup, setShowAddDealerPopup] = useState(false);
  const [newDealer, setNewDealer] = useState({ name: '', phoneNumber: '', password: '', fournisseur_id: fournisseurId });
  const [sendCoins, setSendCoins] = useState({ visible: false, dealerId: null, amount: '' });
  const [editDealer, setEditDealer] = useState({ visible: false, dealerId: null, dealerData: null, fee: 0 });
  const[search,setSearched]=useState("")
  const[totalUsers,setTotalUsers]=useState([])
  useEffect(() => {
    if (fournisseurId) {
      fetchDealers();
    } else {
      console.error('No fournisseurId found in localStorage');
    }
  }, [fournisseurId]);

  const fetchDealers = async () => {
    try {
      const response = await axios.get(`https://ludo216.com/api/getDealersRelatedToFournisseur/${fournisseurId}`);
      
      setDealers(response.data);
    } catch (error) {
      console.error("Error fetching dealers:", error);
      setError('Error fetching dealers');
    }
  };
  useEffect(() => {
     axios.get('https://ludo216.com/api/getAllDealers1')
    .then(r=>setTotalUsers(r.data)).catch(err=>console.log(err))
  }, []);
  useEffect(()=>{
    const data=totalUsers.filter(e=>{
      return e.phoneNumber.includes(search) && e.fournisseur_id===parseInt(fournisseurId)
    })
    setDealers(data)
  },[search]) 
   const handleSearch=()=>{

    const data=totalUsers.filter(e=>{
      return e.phoneNumber.includes(search)
    })
    setDealers(data)
  }
  
 

  const handleCreateDealer = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://ludo216.com/api/addDealerFour', newDealer);
      
      fetchDealers();
      closeAddDealerPopup();
      toast.success("Dealer account successfully added!");  // Success notification
    } catch (error) {
      
      setError('Error creating dealer');
      toast.error("Failed to add dealer account!");  // Error notification
    }
  };

  const openAddDealerPopup = () => setShowAddDealerPopup(true);
  const closeAddDealerPopup = () => {
    setShowAddDealerPopup(false);
    resetNewDealerForm();
  };

  const openSendCoinsPopup = (id) => setSendCoins({ visible: true, dealerId: id, amount: '' });
  const closeSendCoinsPopup = () => setSendCoins({ visible: false, dealerId: null, amount: '' });

  const handleSendCoins = async (event) => {
    event.preventDefault();
    const totalcoins = parseInt(sendCoins.amount, 10);
    
    if (isNaN(totalcoins) || totalcoins < 0) {
      setError('Invalid amount. Only non-negative values are allowed.');
      toast.error("Invalid coin amount!");
      return;
    }

    try {
      await axios.post('https://ludo216.com/api/add-coins', {
        dealerId: sendCoins.dealerId,
        totalcoins,
        fournisseurId: fournisseurId
      });
      setDealers(dealers.map(dealer =>
        dealer.id === sendCoins.dealerId ? { ...dealer, coins: (dealer.coins || 0) + totalcoins } : dealer
      ));
      closeSendCoinsPopup();
      toast.success(`Successfully sent ${totalcoins} coins!`);  // Success notification
    } catch (error) {
      console.error("Error sending coins:", error);
      toast.error("Failed to send coins!");
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setSendCoins({ ...sendCoins, amount: value });
    }
  };

  const handleUpdateDealer = async (event) => {
    event.preventDefault();


    try {
      const response = await axios.put(`https://ludo216.com/api/updateDealer/${editDealer.dealerId}`, {
        ...editDealer.dealerData,
        fee: editDealer.fee,
      });
     
      fetchDealers();
      closeEditDealerPopup();
      toast.success("Dealer details updated successfully!");  // Success notification
    } catch (error) {
      console.error("Error updating dealer:", error);
      setError('Error updating dealer');
      toast.error("Failed to update dealer details!");  // Error notification
    }
  };

  const handleEditDealer = async (dealerId) => {
    const dealer = dealers.find(d => d.id === dealerId);
    if (dealer) {
      setEditDealer({ 
        visible: true, 
        dealerId: dealer.id, 
        dealerData: { ...dealer },
        fee: dealer.fee || 0 
      });
    }
  };

  const closeEditDealerPopup = () => {
    setEditDealer({ visible: false, dealerId: null, dealerData: null, fee: 0 });
  };

  const resetNewDealerForm = () => {
    setNewDealer({ name: '', lastName: '', phoneNumber: '', email: '', password: '', fournisseur_id: fournisseurId });
  };


  return (
    <div className='bg-gray-100 min-h-screen'>
      <div className="flex flex-col md:flex-row ">
        <FournisseursSideNav className="hidden lg:block fixed top-0 left-0 h-full w-64 bg-gray-800 text-white" />
        <div className="flex-1 p-6 bg-gray-100 overflow-y-auto h-screen relative">
          <h1 className="text-2xl font-bold mb-4">Dealers List</h1>
          <div className="flex flex-col lg:flex-row justify-between gap-4 mb-6">
            <button 
              onClick={openAddDealerPopup} 
              className="mb-[10px] left-4 px-4 py-2 bg-blue-500 text-white rounded flex items-center z-20"
            >
              <span className="mr-2">+</span>
              Create Dealer Account
            </button>
            <div className="flex flex-row items-center gap-2">
        <input
          type="text"
          placeholder="Search"
          className="p-2 border border-gray-300 rounded flex-grow"
          onChange={(e) => setSearched(e.target.value)}
        />
        <button
          onClick={handleSearch}
          className="px-4 py-2 bg-gray-500 text-white rounded"
        >
          Search
        </button>
      </div>
          </div>

          {showAddDealerPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg max-w-xs md:max-w-lg w-full">
                <h2 className="text-xl font-bold mb-4">New Dealer</h2>
                <form onSubmit={handleCreateDealer}>
                  {['name', 'phoneNumber', 'password', ].map((field, index) => (
                    <div key={index} className="mb-4">
                      <label className="block text-gray-700 text-sm font-semibold mb-2">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                      <input
                        type={ field === 'password' ? 'password' : 'text'}
                        value={newDealer[field]}
                        onChange={(e) => setNewDealer({ ...newDealer, [field]: e.target.value })}
                        className="form-input mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500"
                        required
                      />
                    </div>
                  ))}
                  <div className="flex justify-end mt-4">
                    <button 
                      type="submit" 
                      className="px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 mr-2"
                    >
                      Create Dealer
                    </button>
                    <button 
                      type="button" 
                      onClick={closeAddDealerPopup} 
                      className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg shadow-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {dealers.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border-collapse border border-gray-300 text-sm lg:text-base">
                <thead className="bg-gray-50">
                  <tr>
                    {['UserName', 'Phone','Fee', 'Coins', 'Actions',].map((header) => (
                      <th key={header} className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {dealers.map((dealer) => (
                    <tr key={dealer.id}>
                      <td className="py-4 px-6 whitespace-nowrap">{dealer.name}</td>
                      <td className="py-4 px-6 whitespace-nowrap">{dealer.phoneNumber}</td>
                      <td className="py-4 px-6 whitespace-nowrap">{dealer.fee || 0}</td>
                      <td className="py-4 px-6 whitespace-nowrap">{dealer.coins || 0}</td>

                      <td className="py-4 px-6 whitespace-nowrap">
                        <button 
                          onClick={() => openSendCoinsPopup(dealer.id)} 
                          className="px-4 py-2 bg-blue-500 text-white rounded-md mr-2"
                        >
                          Send Coins
                        </button>
                        <button 
                          onClick={() => handleEditDealer(dealer.id)} 
                          className="px-4 py-2 bg-yellow-500 text-white rounded-md"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No dealers found</p>
          )}

          {sendCoins.visible && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg max-w-xs md:max-w-lg w-full">
                <h2 className="text-xl font-bold mb-4">Send Coins</h2>
                <form onSubmit={handleSendCoins}>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-semibold mb-2">Amount of Coins</label>
                    <input 
                      type="number" 
                      value={sendCoins.amount}
                      onChange={handleAmountChange}
                      className="form-input mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                  </div>
                  <div className="flex justify-end mt-4">
                    <button 
                      type="submit" 
                      className="px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 mr-2"
                    >
                      Send Coins
                    </button>
                    <button 
                      type="button" 
                      onClick={closeSendCoinsPopup} 
                      className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg shadow-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

{editDealer.visible && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg max-w-xs md:max-w-lg w-full">
      <h2 className="text-xl font-bold mb-4">Edit Dealer</h2>
      <form onSubmit={handleUpdateDealer}>
        {['name', 'phoneNumber', 'password'].map((field, index) => (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 text-sm font-semibold mb-2">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
            <input
              type={ field === 'password' ? 'password' : 'text'}
              value={editDealer.dealerData[field]}
              onChange={(e) => setEditDealer({
                ...editDealer,
                dealerData: { ...editDealer.dealerData, [field]: e.target.value }
              })}
              className="form-input mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500"
              required
            />
          </div>
        ))}

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-semibold mb-2">Fee</label>
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            value={editDealer.fee}
            onChange={(e) => setEditDealer({ ...editDealer, fee: e.target.value })}
            className="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer"
          />
          <div className="text-center mt-2 text-sm text-gray-500">
            {editDealer.fee}%
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button 
            type="submit" 
            className="px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 mr-2"
          >
            Update Dealer
          </button>
          <button 
            type="button" 
            onClick={closeEditDealerPopup} 
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg shadow-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
)}


        </div>
      </div>
      <ToastContainer />
          </div>
  );
}

export default DealersList;