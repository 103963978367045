import React from 'react';
import { FaTachometerAlt, FaUsers, FaHistory } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from './authContext';
import { GiEntryDoor } from "react-icons/gi";

function DealerSideNav() {
  const location = useLocation();
  const { logout } = useAuth();

  const getLinkClassName = (path) => {
    const isActive = location.pathname === path;
    return `flex flex-col items-center justify-center hover:bg-gray-200 p-2 rounded text-gray-800 ${
      isActive ? 'bg-gray-300 border-t-4 border-gray-600' : ''
    }`;
  };

  return (
    <>
      {/* Desktop Sidebar */}
      <div className="h-screen hidden md:block w-[350px] bg-white text-gray-800 flex flex-col shadow-md">
        <div className="p-4 text-center text-2xl font-semibold border-b border-gray-300">
          Admin Dashboard
        </div>
        <nav className="flex-1 p-4">
          <ul>
            <li className="mb-4">
              <Link to="/Admin-dashboard" className={getLinkClassName('/Admin-dashboard')}>
                <FaTachometerAlt className="mr-3 text-2xl" />
                <span className="text-lg">Dashboard</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/fournisseurs" className={getLinkClassName('/fournisseurs')}>
                <FaUsers className="mr-3 text-2xl" />
                <span className="text-lg">Fournisseurs</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/dealers" className={getLinkClassName('/dealers')}>
                <FaUsers className="mr-3 text-2xl" />
                <span className="text-lg">Dealers</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/users" className={getLinkClassName('/users')}>
                <FaUsers className="mr-3 text-2xl" />
                <span className="text-lg">Users</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/rooms" className={getLinkClassName('/rooms')}>
                <GiEntryDoor 
                className="mr-3 text-2xl" />
                <span className="text-lg">Rooms</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/transaction-history" className={getLinkClassName('/transaction-history')}>
                <FaHistory className="mr-3 text-2xl" />
                <span className="text-lg">Transaction History</span>
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/admin-login" className={getLinkClassName('/admin-login')}>
                <span className="text-lg" onClick={() => logout()}>
                  Logout
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Mobile Bottom Navigation */}
      <div className="fixed bottom-0 w-full bg-white overflow-x-auto mt-10 shadow-lg border-t border-gray-300 md:hidden">
        <nav className="flex justify-around p-2">
          <Link to="/Admin-dashboard" className={getLinkClassName('/Admin-dashboard')}>
            <FaTachometerAlt className="text-2xl" />
            <span className="text-xs">Dashboard</span>
          </Link>
          <Link to="/fournisseurs" className={getLinkClassName('/fournisseurs')}>
            <FaUsers className="text-2xl" />
            <span className="text-xs">Fournisseurs</span>
          </Link>
          <Link to="/dealers" className={getLinkClassName('/dealers')}>
            <FaUsers className="text-2xl" />
            <span className="text-xs">Dealers</span>
          </Link>
          <Link to="/users" className={getLinkClassName('/users')}>
            <FaUsers className="text-2xl" />
            <span className="text-xs">Users</span>
          </Link>
          <Link to="/rooms" className={getLinkClassName('/rooms')}>
            <FaUsers className="text-2xl" />
            <span className="text-xs">Rooms</span>
          </Link>
          <Link to="/transaction-history" className={getLinkClassName('/transaction-history')}>
            <FaHistory className="text-2xl" />
            <span className="text-xs">History</span>
          </Link>
          <button onClick={logout} className="flex flex-col items-center text-gray-800">
            <span className="text-2xl">🔓</span>
           <Link to={'/admin-login'}>
            <span className="text-xs"
            onClick={()=>logout()}
            >Logout</span>
            </Link>
          </button>
        </nav>
      </div>
    </>
  );
}

export default DealerSideNav;
