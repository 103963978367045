import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  // Function to handle APK download
  const handleDownload = () => {
    const apkUrl = "https://drive.google.com/uc?export=download&id=1SyDpWcV3TKHQSPQIPzd1J7F48ds-81DY";  // Replace with your APK file URL
    const link = document.createElement('a');
    link.href = apkUrl;
    link.download = 'LudoGame.apk';  // The default name of the downloaded APK
    link.click();  // Trigger the download
  };

  return (
    <div className="relative flex items-center justify-center h-screen w-full overflow-hidden">
      {/* Background Image with srcSet for PC and Mobile */}
      <img
        srcSet="/mobile.jpeg 500w, /pc.jpeg 1000w"  // Define different image resolutions for mobile and desktop
        sizes="(max-width: 767px) 100vw, 100vw"  // 100% width for mobile, and 100% width for desktop
        src="/pc.jpeg"  // Fallback image for browsers that don't support srcSet
        alt="Ludo Game Background"
        className="absolute inset-0 w-full h-full object-fit lg:object-cover"
      />
      {/* Sign In Button in the Top Right Corner */}
      <div className="absolute top-4 right-4 md:top-8 md:right-8 z-10">
        <Link to="/chooseLogin">
          <button className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white font-semibold py-2 px-4 md:py-3 md:px-6 rounded-lg shadow-lg transition duration-300">
            Sign In
          </button>
        </Link>
      </div>

      {/* Enhanced Overlay Content with Download Button */}
      <div className="relative z-10 text-center text-white p-6 md:p-10 rounded-lg shadow-lg bg-blue-950 bg-opacity-70 max-w-sm md:max-w-md mx-auto">
        <h1 className="text-3xl md:text-5xl font-extrabold mb-4 tracking-wide">Welcome to LUDO216</h1>
        <p className="text-base md:text-lg mb-6 tracking-tight">Click this button below to download the game.</p>
        <div className="flex justify-center">
          <button
            onClick={handleDownload}  // Trigger download on click
            className="bg-gradient-to-r from-yellow-500 to-yellow-500 hover:from-yellow-600 hover:to-yellow-700 text-white font-semibold py-2 px-4 md:py-3 md:px-6 rounded-lg shadow-lg transition duration-300"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
