import React, { useEffect, useState } from 'react';
import DealerSideNav from './DealerSideNav'; // Assume you already have this component
import { FaUsers, FaCoins, FaChartLine, FaHistory } from 'react-icons/fa';
import { Line } from 'react-chartjs-2'; // For graphs
import 'chart.js/auto';
import { useAuth } from './authContext';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { GrUpdate } from "react-icons/gr";
import { HiX } from 'react-icons/hi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DealerHome = () => {
  const {isAuthenticated}=useAuth()
  const navigate=useNavigate()
  useEffect(()=>{
    if (!isAuthenticated){
      return navigate('/admin-login')
    }

  },[])
  // Sample data for metrics and chart
  const [newMonths,setNewMonths]=useState([])
  const[dealers,setDealers]=useState(0)
  const[transaction,setTransaction]=useState(0)
  const[coins,setCoins]=useState(0) 
  const[users,setUsers]=useState(0) 
  const[hist,setHist]=useState([])
  var t=[]
  const [chartData, setChartData] = useState({
    labels: newMonths,
    datasets: [
      {
        label: 'Transactions Over Time',
        data: t, // This will hold your dynamic data
        fill: false,
        borderColor: '#4f46e5',
        tension: 0.1,
      },
    ],
  });
  const[diamondRate,setDiamondRate]=useState(null)
  const[refresh,setRefresh]=useState(false)
  useEffect(() => {
    
    axios.get('https://ludo216.com/api/getAllDealers').then(r=>
      
      {setDealers(r.data.users.length)})
    .catch(err=>console.log(err))
    axios.get('https://ludo216.com/api/history/fournisseur').then(r=>setHist(r.data[r.data.length-1]))
    .catch(err=>console.log(err))
    axios.get('https://ludo216.com/api/users/getAll').then(r=>setUsers(r.data.users.length))
    .catch(err=>console.log(err))
    axios.get('https://ludo216.com/api/getCoins').then(r=>{setCoins(r.data.totalcoins)
      setDiamondRate(r.data.diamondRate)
    })
    .catch(err=>console.log(err))
    axios.get('https://ludo216.com/api/allFournisseur').then(r=>{
      setTransaction(r.data.users.length)
    
    
   
    })
    .catch(err=>console.log(err))
   
    
  

      const fetchTransactionData = async () => {
       
        
        const months = [
          "Jan", "Feb", "Mar", "Apr", "May", "June",
          "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        
  
       
        
        // Reverse the months array to show oldest first
        
  
        // Fetch transactions for the last 6 months from your API/DB
        // Assuming the API returns data for each month in sequence
        var t=[]
        var m=[]
        try {
          const response = await axios.get('https://ludo216.com/api/history/transaction'); // Adjust the endpoint to your needs
         
          response.data.forEach(e=>{
              t.push(e.totalTransactions)
              m.push(months[e.month-1])
            })
            
            // Push the transaction data for each month into the array
        } catch (error) {
          console.error('Error fetching transaction data:', error);
        }
        // Set the new months and transaction data in chartData
       
        setChartData(prevChartData => ({
          ...prevChartData,
          labels: m,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: t, // Update chart with dynamic data
            },
          ],
        }));
      };
      
      fetchTransactionData(); // Call the function
    }, [refresh]);
    const[p,setP]=useState(false)
    const[g,setG]=useState(0)
    const handleSave=()=>{
      axios.put('https://ludo216.com/api/updateDiamondRate',{diamondRate:g})
      .then(r=>{
        setRefresh(!refresh)
        toast.success(' Updated Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              })
            setP(false)
            })
      .catch(err=>{toast.error("error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              })
            setP(false)
            })
    }
  return (
    <div className="flex min-h-screen  bg-gray-100">
      {/* Sidebar */}
      <DealerSideNav />

      {/* Main Content */}
      <div className="flex-1 p-6">
        {/* Top Bar */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-semibold">Amdin Dashboard</h1>
         
        </div>

        {/* DealerHome Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaCoins className="text-blue-600 text-3xl mr-4" />
            <div>
              <p className="text-sm text-gray-500">Total Coins</p>
              <p className="text-xl font-semibold">{coins?coins:0}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaCoins className="text-blue-600 text-3xl mr-4" />
            <div>
              <div className='flex justify-evenly gap-11'>
              <p className="text-sm text-gray-500">Diamond Rate</p>
              <GrUpdate className='cursor-pointer'
                onClick={()=>setP(true)}
              />
            {p&&(
           <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
           <div className="bg-white w-[300px] p-6 rounded-lg shadow-lg">
             <div className="flex justify-between items-center mb-4">
               <h1 className="text-lg font-semibold text-gray-800">New Diamond Rate</h1>
               <HiX
                 size={24}
                 className="cursor-pointer text-red-500 hover:text-red-700 transition"
                 onClick={() => setP(false)}
               />
             </div>
             <input
               type="number"
               placeholder="Enter Diamond Rate"
               className="w-full px-4 py-2 border border-gray-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
               onChange={(e) => setG(e.target.value)}
             />
             <button
               onClick={() => handleSave()}
               className="w-full bg-blue-500 text-white py-2 rounded-lg font-semibold hover:bg-blue-600 transition"
             >
               Submit
             </button>
           </div>
         </div>
         
            )}
              </div>
              <p className="text-xl font-semibold">{diamondRate?diamondRate:0}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaUsers className="text-green-600 text-3xl mr-4" />
            <div>
              <p className="text-sm text-gray-500">Total Dealers</p>
              <p className="text-xl font-semibold">{dealers?dealers:0}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
            <FaUsers className="text-purple-600 text-3xl mr-4" />
            <div>
              <p className="text-sm text-gray-500">Active Users</p>
              <p className="text-xl font-semibold">{users?users:0}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
          <FaUsers className="text-purple-600 text-3xl mr-4" />
            <div>
              <p className="text-sm text-gray-500">Total Fournisseurs</p>
              <p className="text-xl font-semibold">{transaction?transaction:0}</p>
            </div>
          </div>
        </div>

        {/* Graph and Table */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Transactions Over Time (Graph) */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Transactions Over Time</h2>
            <Line data={chartData} />
          </div>

          {/* Recent Transactions (Table) */}
          <div className="bg-white p-6 rounded-lg w-full shadow-md">
            <h2 className="text-lg font-semibold mb-4">Recent Transactions</h2>
            {hist ? (
              <div className="overflow-x-auto">
                <table className="min-w-full text-[12px] bg-white border border-gray-300">
                  <thead>
                    <tr>
                      <th className="py-3 px-6 border border-gray-300">Fournisseur</th>
                      <th className="py-3 px-6 border border-gray-300">Action</th>
                      <th className="py-3 px-6 border border-gray-300">Dealer</th>
                      <th className="py-3 px-6 border border-gray-300">Total Coins</th>
                      <th className="py-3 px-6 border border-gray-300">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example Data */}
                    <tr className="hover:bg-gray-100">
                      <td className="py-3 px-6 border border-gray-300">{hist?.Fournisseur?.name} {hist?.Fournisseur?.lastName}</td>
                      <td className="py-3 px-6 border border-gray-300 text-center">
                        <span className="text-green-500">Transfer</span>
                      </td>
                      <td className="py-3 px-6 border border-gray-300">{hist?.Dealer?.name} {hist?.Dealer?.lastName}</td>
                      <td className="py-3 px-6 border border-gray-300 text-center">{hist?.totalcoins || 0}</td>
                      <td className="py-3 px-6 border border-gray-300 text-center text-[10px]">
                        {hist?.createdAt?.slice(0, 10) || "x"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <h1>No Transaction Found.</h1>
            )}
          </div>
        </div>
      </div>
      <div>
      <ToastContainer />
    </div>
    </div>
  );
};

export default DealerHome;
