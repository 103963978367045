import React from 'react';
import { Link } from 'react-router-dom'; // For navigation (React Router v6+)

function ChooseLogin() {
  return (
    <div className="min-h-screen flex items-center justify-center py-8 px-4 sm:px-6 lg:px-8">
          <img
        srcSet="/mobile.jpeg 500w, /pc.jpeg 1000w"  // Define different image resolutions for mobile and desktop
        sizes="(max-width: 767px) 100vw, 100vw"  // 100% width for mobile, and 100% width for desktop
        src="/pc.jpeg"  // Fallback image for browsers that don't support srcSet
        alt="Ludo Game Background"
        className="absolute z-[-1] w-full h-full object-fit lg:object-cover"
      />
      <div className="bg-blue-950 bg-opacity-70 p-8 rounded-lg shadow-lg w-full max-w-md text-center ">
        <h2 className="text-3xl font-semibold mb-6 text-white">Choose Your Login</h2>
        
        {/* Dealer Login Button */}
        <div className='flex flex-row justify-between gap-4'>
        <Link
          to="/login-dealer"
          className="w-full h-20 p-6 bg-blue-600 text-white rounded-md text-lg font-semibold hover:border hover:border-white hover:border-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
        >
         Dealer
        </Link>

        {/* Fournisseur Login Button */}
        <Link
          to="/login-fournisseur"
          className="w-full h-20 p-6 bg-green-600 text-white rounded-md text-lg font-semibold hover:border hover:border-white hover:border-2 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
        >
          Fournisseur
        </Link>
        </div>
      </div>
    </div>
  );
}

export default ChooseLogin;
